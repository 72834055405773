import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './routes/App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import ThemeCustomization from './themes/ThemeCustomization';
import AuthProvider from './providers/AuthProvider';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {store} from './store';
import {Provider} from 'react-redux';
import Toast from './components/Toast';
import LocalStorageProvider from './providers/LocalStorageProvider';
import {LicenseInfo} from '@mui/x-license-pro';

const root = ReactDOM.createRoot(document.getElementById('root'));

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

root.render(
    <React.StrictMode>
        <ThemeCustomization>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <AuthProvider>
                    <BrowserRouter>
                        <Provider store={store}>
                            <LocalStorageProvider>
                                <App/>
                                <Toast/>
                            </LocalStorageProvider>
                        </Provider>
                    </BrowserRouter>
                </AuthProvider>
            </LocalizationProvider>
        </ThemeCustomization>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
